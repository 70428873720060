import type { Survey } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import type { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import MarkdownConverter from "lib/ui/components/markdown/MarkdownConverter"
import React from "react"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import type { ReportVariant } from "../../WellbeingReport"
import CustomAreaInviteFriends from "./CustomAreaInviteFriends"
import CustomAreaMasterClass from "./CustomAreaMasterClass"
import CustomAreaPerformanceModes from "./CustomAreaPerformanceModes"
import CustomAreaWellbeingConsultation from "./CustomAreaWellbeingConsultation"

interface Props extends ViewComponentProps {
  survey: Survey
  reportVariant: ReportVariant
}

export default class ReportSectionCustomArea extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionCustomArea"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    if (!this.props.survey?.report_custom_message?.en) return false

    return (
      <>
        {this.props.reportVariant === "pdf" && <div className="hard-page-break" />}
        {this.renderCustomAreas()}
      </>
    )
  }

  private renderCustomAreas() {
    const segmentArrays = this.splitToSegmentArrays(
      this.props.survey.report_custom_message?.[this.localization.currentLanguage] ??
        this.props.survey.report_custom_message?.en ??
        ""
    )

    return segmentArrays.map((segmentArray, i) => this.renderSegmentArray(segmentArray, i))
  }

  renderSegmentArray(segmentArray: string[], key: number) {
    const { reportVariant } = this.props

    return (
      <React.Fragment key={key}>
        <Grid container>
          <Grid container spacer spacerSize={reportVariant === "mobile" ? 2 : 6} />
        </Grid>
        <Grid
          container
          className={classNames(reportVariant === "mobile" ? styles.containerMobile : styles.container, "same-page")}
        >
          {segmentArray.map((segment, i) => this.renderSegment(segment))}
        </Grid>
      </React.Fragment>
    )
  }

  private renderSegment(segment: string) {
    const { reportVariant } = this.props

    switch (segment) {
      case "{{performance_modes}}":
        return <CustomAreaPerformanceModes reportVariant={reportVariant} />
      case "{{wellbeing_consultation}}":
        return <CustomAreaWellbeingConsultation reportVariant={reportVariant} />
      case "{{masterclass}}":
        return <CustomAreaMasterClass />
      case "{{invite_friends}}":
        return <CustomAreaInviteFriends reportVariant={reportVariant} />
      default:
        return (
          <Grid xs={12} className={styles.markdownAreaContainer}>
            <MarkdownConverter linksInNewWindow linkColor={this.theme.colors.secondary.toString()}>
              <Markdown content={segment} />
            </MarkdownConverter>
          </Grid>
        )
    }
  }

  private splitToSegmentArrays(input: string) {
    const segments = this.splitToSegments(input)
    const segmentContainers: string[][] = []

    const containerSplit = "{{break}}"
    const singleContainerSegments = [
      "{{invite_friends}}",
      "{{wellbeing_consultation}}",
      "{{performance_modes}}",
      "{{masterclass}}"
    ]

    let currentContainer: string[] = []
    for (const segment of segments) {
      if (singleContainerSegments.includes(segment)) {
        segmentContainers.push(currentContainer)
        segmentContainers.push([segment])
        currentContainer = []
      } else if (segment === containerSplit) {
        segmentContainers.push(currentContainer)
        currentContainer = []
      } else {
        currentContainer.push(segment)
      }
    }
    segmentContainers.push(currentContainer)

    return segmentContainers.filter(container => container.length > 0)
  }

  private splitToSegments(input: string): string[] {
    const regex = /({{[^}]+}})/g
    const result: string[] = []
    let lastIndex = 0

    input.replace(regex, (match, p1, offset) => {
      if (lastIndex < offset) {
        result.push(input.slice(lastIndex, offset).trim())
      }
      result.push(match)

      lastIndex = offset + match.length
      return match
    })

    // Add the remaining part of the string after the last match if it exists
    if (lastIndex < input.length) {
      result.push(input.slice(lastIndex).trim())
    }

    return result.filter(part => part.length > 0)
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    container: {
      minWidth: "40rem",
      marginBottom: "2rem",
      border: "double 3px transparent",
      borderRadius: "1rem",
      // Hack to combine border radius with linear-gradient. The biggest apparent downside
      // is the need to have a solid background. Please don't spend too much time
      // looking for a better solution unless there's an actual issue.
      background: `linear-gradient( ${ReportSectionCustomArea.theme.colors.hintsa.charcoal.toString()}, ${ReportSectionCustomArea.theme.colors.hintsa.charcoal.toString()}) padding-box, linear-gradient(92.7deg, #EA9F88 0%, #FFE0D4 20%, #F8F6E3 40%, #B8CBC6 60%, #7996A1 80%, #537D81 100%) border-box;`
    },
    containerMobile: {
      marginBottom: "2rem",
      marginLeft: "-1rem",
      marginRight: "-1rem",
      width: csx.important("unset")
    },
    markdownAreaContainer: {
      padding: reportVariant === "mobile" ? "1rem 2rem" : "2rem 3rem"
    }
  })
}
