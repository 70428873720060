import * as csx from "csx"
import { Fragment } from "react"
import { stylesheet } from "typestyle"

import type { Answer } from "app/surveys/modules/state/model/Model"
import type { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import {
  getAnswerMaxPoints,
  getAnswerMinPoints,
  getAnswerPoints,
  getAnswerTitle,
  getQuestionType,
  getScoreColorSetpointsForAnswer
} from "lib/utils/report/FeedbackPersonalReportUtils"

import classNames from "classnames"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { reportLargeHeadingClass, reportSmallBodyClass } from "../../FeedbackReport"
import { ReportVariant } from "../../WellbeingReport"
import ScoreMeter from "../../ui/ScoreMeter"

interface Props extends ViewComponentProps {
  answers: Answer[]
  reportVariant: ReportVariant
}

export default class ReportSectionFeedback extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionFeedback"]
  }

  constructor(props: Props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <div className={styles.containerOuter}>
        <div className={reportVariant === "mobile" ? undefined : styles.containerInner}>
          <Grid container>
            {this.renderTitle()}
            {this.renderAnswers()}
          </Grid>
        </div>
      </div>
    )
  }

  private renderTitle() {
    return (
      <Grid sm={12}>
        <Typography
          component="h1"
          useContrastColor
          className={classNames(reportLargeHeadingClass, styles.congratulationsClass)}
        >
          {this.txt("title")}
        </Typography>
      </Grid>
    )
  }

  private renderAnswers() {
    const { answers } = this.props

    return (
      <>
        {answers.map(a => this.renderAnswer(a))}
        <Grid sm={12} className={styles.hrGrid}>
          <hr className={styles.hr} />
        </Grid>
        <Grid spacer spacerSize={0.5} />
      </>
    )
  }

  private renderAnswer(answer: Answer, comparisonAnswer?: Answer) {
    const { reportVariant } = this.props

    return (
      <Fragment key={answer.question_id}>
        <Grid sm={12} className={styles.hrGrid}>
          <hr className={styles.hr} />
        </Grid>
        <Grid className={styles.questionName} sm={9} xs={12}>
          <Typography useContrastColor className={reportSmallBodyClass}>
            {this.txt(answer.title)}
            {this.renderFeedbackAnswerResult(answer)}
          </Typography>
        </Grid>
        <Grid sm={3} xs={12} />
      </Fragment>
    )
  }

  private renderFeedbackAnswerResult(answer: Answer) {
    switch (getQuestionType(answer)) {
      case "boolean":
        return (
          <div
            className={classNames(
              styles.booleanResult,
              answer?.options[0]?.points === 1 ? styles.positive : styles.negative
            )}
          >
            {this.txt(answer?.options[0]?.title)}
          </div>
        )
      case "field":
        return <div className={styles.fieldResult}>{answer?.options[0]?.note}</div>
      case "radio":
        return (
          <div className={styles.smallScoreMeter}>
            <ScoreMeter
              score={getAnswerPoints(answer)}
              scoreDescription={getAnswerTitle(answer, this.localization)}
              minScore={getAnswerMinPoints(answer)}
              maxScore={getAnswerMaxPoints(answer)}
              showBottomScoreArea
              bottomScoreType="answerOption"
              scoreColorCalculationMethod="value"
              scoreColorSetpoints={getScoreColorSetpointsForAnswer(answer)}
            />
          </div>
        )
    }
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    containerOuter: {
      margin: reportVariant === "mobile" ? undefined : "2rem 2rem"
    },
    questionName: {
      display: "flex",
      alignItems: "center",
      marginBottom: reportVariant === "mobile" ? csx.important("-1.5rem") : undefined
    },
    hr: {
      marginTop: "1rem",
      backgroundColor: ReportSectionFeedback.theme.colors.hintsa.charcoal.toString(),
      opacity: 0.5,
      borderStyle: "dashed"
    },
    hrGrid: {
      width: "100%"
    },
    smallScoreMeter: {
      width: "30rem",
      marginTop: "2rem",
      marginBottom: "-1rem"
    },
    positive: {
      color: ReportSectionFeedback.theme.colors.positiveColor.toString()
    },
    negative: {
      color: ReportSectionFeedback.theme.colors.negativeColor.toString()
    },
    booleanResult: {
      marginTop: "0.5rem",
      fontWeight: "bold"
    },
    fieldResult: {
      marginTop: "0.5rem",
      fontStyle: "italic"
    }
  })
}
