import Container from "@material-ui/core/Container"
import { style, stylesheet } from "typestyle"

import { Survey, SurveyAnswerInstance, User } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../../base/ViewComponent"
import ReportSectionCustomArea from "./sections_wellbeing/9_custom_area/ReportSectionCustomArea"

import { ReportVariant } from "./WellbeingReport"
import ReportSectionFeedback from "./sections_feedback/1_feedback/ReportSectionFeedback"

interface PageProps extends ViewComponentProps {
  answer: SurveyAnswerInstance
  language: string
  reportVariant: ReportVariant
  survey: Survey
  user: User | undefined
  isDownloadingPdf: boolean
  onDownloadPdf: () => void
}

export default class FeedbackReport extends ViewComponent<PageProps> {
  get componentName() {
    return ["report", "pages", "report", "FeedbackReport"]
  }

  private isFirstRender = true

  constructor(props: PageProps) {
    super(props)

    setupStyles(props.reportVariant)
  }

  shouldComponentUpdate(nextProps: PageProps) {
    if (
      this.isFirstRender ||
      nextProps.language !== this.props.language ||
      nextProps.isDownloadingPdf !== this.props.isDownloadingPdf
    ) {
      this.isFirstRender = false
      return true
    }

    return false
  }

  render() {
    super.render()

    const { answer, language, reportVariant, survey, isDownloadingPdf, onDownloadPdf } = this.props

    if (!answer) return null

    return (
      <Container maxWidth="lg" className={reportVariant === "mobile" ? styles.containerMobile : undefined}>
        <div>
          <ReportSectionFeedback answers={answer.answers} reportVariant={reportVariant} />
          <ReportSectionCustomArea survey={survey} reportVariant={reportVariant} />
        </div>
      </Container>
    )
  }
}

const styles = stylesheet({
  containerMobile: { padding: "0 !important" }
})

export let reportLargeHeadingClass: string
// export let reportMediumHeadingClass: string
// export let reportSmallHeadingClass: string
// export let reportSubHeadingClass: string
export let reportBodyClass: string
export let reportSmallBodyClass: string

const setupStyles = (reportVariant: ReportVariant) => {
  reportLargeHeadingClass = style({
    fontSize: reportVariant === "mobile" ? "52px" : "100px",
    fontWeight: 300,
    letterSpacing: "-2.4px",
    marginBottom: "1rem",
    lineHeight: reportVariant === "mobile" ? "110%" : "normal"
  })

  reportBodyClass = style({
    fontSize: reportVariant === "mobile" ? "18px" : "22px",
    lineHeight: "120%"
  })

  reportSmallBodyClass = style({
    fontSize: "16px"
  })
}
