import { ApiDescription, ConversionMap, ConversionMapRoot } from "core/modules/state/conversionmap/ConversionMap"
import {
  DateTimeValueConverter,
  DateValueConverter,
  LinkArrayConverter,
  LinkConverter
} from "core/modules/state/conversionmap/ValueConverters"

import { BaseModule } from "../../../controller/Module"
import { QuestionCategoryConverter } from "./QuestionCategoryConverter"

export class ConversionMapModule extends BaseModule implements ConversionMap {
  get moduleName() {
    return "ConversionMap"
  }

  get map(): ConversionMapRoot {
    return conversionMapContents
  }
}

/**
 * Describes how objects are translated between internal JS format API JSON
 */
const conversionMapContents: any = {
  Answer: {
    options: new LinkArrayConverter("QuestionOption")
  },
  Consent: {
    created_at: new DateTimeValueConverter(),
    __api: <ApiDescription>{
      create: {
        segments: ["me", "consents"],
        search: [{ name: "access_token" }]
      }
    }
  },
  Invite: {
    user: new LinkConverter("User")
  },
  Item: {
    entry: new LinkConverter("Unknown", { typeKey: "kind" })
  },
  Media: {
    created_at: new DateTimeValueConverter()
  },
  Organisation: {},
  OrganisationSurvey: {
    ends_on: new DateValueConverter(),
    started_at: new DateValueConverter()
  },
  Question: {
    category: new QuestionCategoryConverter(),
    options: new LinkArrayConverter("QuestionOption", {
      conversion: "inline"
    })
  },
  QuestionOption: {},
  RequestAction: {},
  Survey: {
    questions: new LinkArrayConverter("Question", { conversion: "inline" }),
    __api: <ApiDescription>{
      get: {
        segments: ["surveys", { name: "id" }],
        search: [{ name: "access_token" }]
      },
      create: {
        segments: ["surveys"],
        search: [{ name: "access_token" }]
      },
      update: {
        segments: ["surveys", { name: "id" }],
        search: [
          { name: "access_token" },
          {
            name: "survey_id",
            paramName: "surveyId",
            required: true
          },
          {
            name: "planned_survey_id",
            paramName: "plannedSurveyId",
            required: true
          },
          {
            name: "selected_tags",
            paramName: "selectedTags",
            required: true
          }
        ]
      }
    }
  },
  SurveyResult: {
    date: new DateValueConverter(),
    user: new LinkConverter("User"),
    answer: new LinkArrayConverter("Answer", { conversion: "inline" }),
    __api: <ApiDescription>{
      get: {
        segments: ["survey_results", { name: "id" }],
        search: [{ name: "access_token" }, { name: "client_id" }, { name: "client_secret" }]
      },
      create: {
        segments: ["survey_results"],
        search: [
          { name: "access_token" },
          { name: "client_id" },
          { name: "client_secret" },
          { name: "company_code", paramName: "companyCode" },
          { name: "m", paramName: "surveyMeta" },
          { name: "organisation_survey_id", paramName: "organisationSurveyId" },
          { name: "s", paramName: "surveyParams" }
        ]
      },
      update: {
        segments: ["survey_results", { name: "id" }],
        search: [{ name: "access_token" }]
      }
    }
  },
  SurveyResultsExtra: {
    __api: <ApiDescription>{
      documentType: "SurveyResultsExtra",
      update: {
        segments: ["survey_results", { name: "reportId" }, "extra"],
        search: [{ name: "id", paramName: "answerId" }, { name: "client_id" }, { name: "client_secret" }]
      }
    }
  },
  Tag: {},
  UnifiedSurvey: {
    survey: new LinkConverter("Survey"),
    organisation_survey: new LinkConverter("OrganisationSurvey"),
    __api: <ApiDescription>{
      get: {
        segments: ["surveys"],
        search: [
          { name: "client_id" },
          { name: "client_secret" },
          { name: "m", paramName: "surveyMeta" },
          { name: "s", paramName: "surveyParams" },

          // @deprecated
          { name: "company_code", paramName: "companyCode" },
          { name: "id" },
          { name: "planned_survey_id", paramName: "plannedSurveyId" }
        ]
      }
    }
  },
  User: {
    created_at: new DateTimeValueConverter(),
    birthday: new DateTimeValueConverter()
  },
  UserUpdate: {
    __api: <ApiDescription>{
      documentType: "UserUpdate",
      update: {
        segments: ["me", "edit_user"],
        search: [{ name: "access_token" }]
      }
    }
  },
  EmailVerify: {
    __api: <ApiDescription>{
      documentType: "EmailVerify",
      update: {
        segments: ["emails", "verify"],
        search: [{ name: "access_token" }]
      }
    }
  },
  EmailValidateCheck: {
    __api: <ApiDescription>{
      documentType: "EmailValidateCheck",
      create: {
        segments: ["surveys", "check_email"],
        search: [
          { name: "client_id" },
          { name: "client_secret" },
          { name: "s", paramName: "surveyParams" },
          { name: "company_code", paramName: "companyCode" },
          { name: "id" }
        ]
      }
    }
  },
  EmailValidateToken: {
    __api: <ApiDescription>{
      documentType: "EmailValidateToken",
      apiRelativePath: ["oauth"],
      create: {
        segments: ["open_link_surveys", "verify_email"],
        search: [
          { name: "email" },
          { name: "magic_token" },
          { name: "client_id" },
          { name: "client_secret" },
          { name: "grant_type" },
          { name: "access_token" }
        ]
      }
    }
  },

  // Views

  Consents: {
    __api: <ApiDescription>{
      documentType: "Consent",
      get: {
        segments: ["me", "consents"],
        search: [{ name: "access_token" }, { name: "scope" }]
      }
    }
  },
  SurveyResults: {
    __api: <ApiDescription>{
      documentType: "SurveyResult",
      get: {
        segments: ["survey_results"],
        search: [
          { name: "access_token" },
          { name: "organisation_survey_id", paramName: "organisationSurveyId" },
          { name: "incomplete" }
        ]
      }
    }
  }
}
