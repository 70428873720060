import { Menu, MenuItem } from "@material-ui/core"
import * as csx from "csx"
import moment from "moment"
import { stylesheet } from "typestyle"

import type { SurveyResult } from "app/surveys/modules/state/model/Model"
import type { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../../../base/ViewComponent"
import ReportSelectorButton from "./ReportSelectorButton"

interface Props extends ViewComponentProps {
  surveyResult: SurveyResult | undefined
  selectedReportIndex: number
  onSelectReport: (index: number) => void
}

interface State extends ViewComponentState {
  anchorEl?: HTMLElement
  selectionTargetIndex?: number
  showOldElementsMenu?: boolean
}

export default class ReportSelector extends ViewComponent<Props, State> {
  get componentName() {
    return ["report", "pages", "report", "ReportSelector"]
  }

  constructor(props: Props) {
    super(props)

    setupStyles()

    this.state = {}
  }

  render() {
    super.render()

    if (!this.props.surveyResult?.instances?.[0]) return null

    return (
      <>
        {this.renderReportButtons()}
        {this.renderOlderReportsMenu()}
      </>
    )
  }

  private renderReportButtons() {
    const { surveyResult, selectedReportIndex, onSelectReport } = this.props

    const current = surveyResult?.instances?.[0]
    const previous = surveyResult?.instances?.[1]

    if (!current) return null

    return (
      <div className={styles.reportSelectorContainerOuter}>
        <div className={styles.reportSelectorContainer}>
          <div className={styles.reportSelectorContainerInner}>
            {surveyResult.instances.length > 2 && (
              <ReportSelectorButton active={selectedReportIndex > 1} onClick={this.onShowOlderReportsMenu}>
                {selectedReportIndex > 1
                  ? this.formatDate(surveyResult.instances[selectedReportIndex].date)
                  : `${this.txt("older")}…`}
              </ReportSelectorButton>
            )}
            {previous && (
              <ReportSelectorButton
                active={selectedReportIndex === 1}
                onClick={() => {
                  onSelectReport(1)
                }}
              >
                {this.formatDate(previous.date)}
              </ReportSelectorButton>
            )}
            {!previous && <ReportSelectorButton disabled>{this.txt("no_previous_version")}</ReportSelectorButton>}
            <ReportSelectorButton
              active={selectedReportIndex === 0}
              onClick={() => {
                onSelectReport(0)
              }}
            >
              {this.formatDate(current.date)}
            </ReportSelectorButton>
          </div>
        </div>
      </div>
    )
  }

  private renderOlderReportsMenu() {
    const { anchorEl, showOldElementsMenu } = this.state

    if (!showOldElementsMenu) return null

    return (
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={this.onOldReportsMenuClose}>
        {this.renderMenuItems()}
      </Menu>
    )
  }

  private renderMenuItems(): JSX.Element[] {
    const { surveyResult, selectedReportIndex, onSelectReport } = this.props

    const oldInstances = surveyResult?.instances.slice(2)!

    return oldInstances.map((answer, i) => {
      const active = selectedReportIndex === i + 2

      return (
        <MenuItem
          key={i}
          selected={active}
          onClick={() => {
            onSelectReport(i + 2)
            this.onOldReportsMenuClose()
          }}
        >
          {this.formatDate(answer.date)}
        </MenuItem>
      )
    })
  }

  private onShowOlderReportsMenu = event => {
    const target = event.currentTarget
    this.setState({ anchorEl: target, showOldElementsMenu: true })
  }

  private onOldReportsMenuClose = () => {
    this.setState({ anchorEl: undefined, showOldElementsMenu: false })
  }

  private formatDate(date: string): string {
    const momentDate = moment(date, "YYYY-MM-DD")
    const day = momentDate.format("D")
    const monthName = momentDate.format("MMMM").toLowerCase()
    const month = this.txt("months", monthName)
    const year = momentDate.format("YYYY")

    return `${day} ${month} ${year}`
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    reportSelectorContainerOuter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem",
      marginTop: "2rem"
    },
    reportSelectorContainer: {
      backgroundColor: ReportSelector.theme.colors.hintsa.charcoal.toString(),
      padding: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.5rem"
    },
    reportSelectorContainerInner: {
      padding: "0.25rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "0.125rem",
      backgroundColor: csx.rgba(120, 120, 128, 0.35).toString()
    }
  })
}
