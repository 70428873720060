import { validate } from "email-validator"
import { useState } from "react"

export function useValidEmail(allowedDomains?: string) {
  const [email, setEmail] = useState<string>()

  const cleanEmail = email?.trim().toLowerCase() ?? ""
  const isValidEmail = validate(cleanEmail)

  const domain = cleanEmail.split("@")[1]
  const validDomains =
    allowedDomains
      ?.split(",")
      .map(domain => domain.toLowerCase().trim())
      .filter(Boolean) ?? []
  const isValidDomain = !validDomains.length || validDomains.includes(domain)

  return { email, setEmail, isValidEmail, isValidDomain, validDomains }
}
