import { OrganisationSurveyTag } from "app/surveys/modules/state/model/Model"
import type { ScheduledSurvey, Survey, Team } from "app/surveys/modules/state/model/Model"
import TeamSelectionDialog from "app/surveys_app/components/ui/dialogs/select_team/TeamSelectionDialog"
import type { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../../base/LogicComponent"
import PersonalDetailsPageView from "./PersonalDetailsPageView"

export interface PersonalDetailsResults {
  firstName: string
  lastName: string
  selectedTeam: string
  selectedTags: string[]
}

interface Props extends LogicComponentProps {
  organisationSurvey: ScheduledSurvey
  survey: Survey
  maxPage: number
  language: string
  onDone: (results: PersonalDetailsResults) => void
  onPrevious: () => void
}

interface State extends LogicComponentState {
  firstName: string
  lastName: string
  selectedTeam: string
  showNestedTeamsDialog?: boolean
  tagSelection: { [category: string]: string }
}

export default class PersonalDetailsPage extends LogicComponent<Props, State> {
  get componentName() {
    return ["survey", "pages", "personal_details", "PersonalDetailsPage"]
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      firstName: "",
      lastName: "",
      selectedTeam: "",
      tagSelection: {}
    }
  }

  private enabledTagCategories: string[] = []
  private organisationSurveyTags: string[] = []

  render() {
    super.render()

    const { survey, maxPage, language, onPrevious, organisationSurvey } = this.props
    const { firstName, lastName, selectedTeam } = this.state

    // Filter out empty categories, can't use enabled_tag_categories directly
    this.enabledTagCategories = [
      ...new Set(organisationSurvey.enabled_tags?.map(category => category.category_name ?? ""))
    ]

    this.organisationSurveyTags = organisationSurvey.organisation_survey_tags ?? []

    return (
      <>
        {this.renderDialogs()}
        <PersonalDetailsPageView
          maxQuestions={survey.questions.length}
          survey={survey}
          maxPage={maxPage}
          language={language}
          logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
          onSelectTeam={this.onSelectTeam}
          selectedTeam={selectedTeam}
          teams={organisationSurvey.teams as Team[]}
          enabledTags={organisationSurvey?.enabled_tags ?? []}
          enabledTagCategories={this.enabledTagCategories}
          tagSelection={this.state.tagSelection}
          doneEnabled={this.validateValues()}
          organisationSurveyTags={this.organisationSurveyTags}
          firstName={firstName}
          lastName={lastName}
          onDone={this.onDone}
          onPrevious={onPrevious}
          onSetFirstName={this.onSetFirstName}
          onSetLastName={this.onSetLastName}
          onShowNestedTeams={this.onShowNestedTeams}
          onSetTagSelection={this.onSetTagSelection}
        />
      </>
    )
  }

  private renderDialogs() {
    const { showNestedTeamsDialog } = this.state
    const { organisationSurvey } = this.props

    if (showNestedTeamsDialog) {
      return (
        <TeamSelectionDialog
          teams={organisationSurvey.teams as Team[]}
          allowParentTeamSelection={organisationSurvey?.organisation_survey_tags?.includes(
            OrganisationSurveyTag.AllowSelectingParentTeam
          )}
          onSelectTeam={(selectedTeam: string) =>
            this.setState({ showNestedTeamsDialog: false, selectedTeam: selectedTeam?.toString() })
          }
          onClose={() => this.setState({ showNestedTeamsDialog: false })}
        />
      )
    }
  }

  private validateValues() {
    const { firstName, lastName, selectedTeam, tagSelection } = this.state
    const { organisationSurvey } = this.props

    const organisationSurveyTags = organisationSurvey.organisation_survey_tags

    if (organisationSurveyTags.includes(OrganisationSurveyTag.AskUserName)) {
      if (!firstName.trim() || !lastName.toString()) return false
    }

    if (
      organisationSurveyTags.includes(OrganisationSurveyTag.SelectTeam) &&
      !!organisationSurvey.teams?.length &&
      !selectedTeam
    )
      return false

    if (
      organisationSurveyTags.includes(OrganisationSurveyTag.SelectTags) &&
      this.enabledTagCategories.length > 0 &&
      this.enabledTagCategories.map(category => tagSelection[category]).filter(Boolean).length <
        this.enabledTagCategories.length
    ) {
      return false
    }

    return true
  }

  private onSelectTeam = (selectedTeam?: string) => {
    this.setState({ selectedTeam: selectedTeam ?? "" })
  }

  private onSetFirstName = (firstName: string) => {
    this.setState({ firstName })
  }

  private onSetLastName = (lastName: string) => {
    this.setState({ lastName })
  }

  private onDone = () => {
    const { firstName, lastName, selectedTeam, tagSelection } = this.state
    const { onDone, organisationSurvey } = this.props

    const selectedTags = (organisationSurvey.enabled_tag_categories ?? []).map(
      category => tagSelection[category.category_name]
    )

    onDone({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      selectedTeam: selectedTeam.trim(),
      selectedTags
    })
  }

  private onShowNestedTeams = () => {
    this.setState({ showNestedTeamsDialog: true })
  }

  private onSetTagSelection = (tagId?: string) => {
    const { organisationSurvey } = this.props

    const tags = organisationSurvey.enabled_tags
    const category = tags.find(t => t.id == tagId)?.category_name
    if (!category || !tagId) return

    this.updateState(state => {
      state.tagSelection[category] = tagId
    })
  }
}
