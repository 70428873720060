import Typography from "@material-ui/core/Typography"
import { style } from "typestyle"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import CardLayout from "lib/ui/components/layout/CardLayout"

import { ViewComponent } from "../../../base/ViewComponent"

interface ThankYouPageViewProps extends ViewComponentProps {
  resultMessage?: string
  thankYouImage: string
  showReportButton: boolean
  logo: string
  onReport: () => void
}

export default class ThankYouPageView extends ViewComponent<ThankYouPageViewProps> {
  get componentName() {
    return ["survey", "pages", "thank_you", "ThankYouPageView"]
  }

  get logoContainerClass() {
    return style({
      display: "flex",
      flexFlow: "row-reverse"
    })
  }

  get headerClass() {
    return style({
      margin: "2.5rem 2.5rem 0 2.5rem"
    })
  }

  get outerContainerClass() {
    return style({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    })
  }

  get contentClass() {
    return style({
      margin: "1rem 2rem",
      display: "flex",
      flexDirection: "column",
      textAlign: this.isMobile ? undefined : "center"
    })
  }

  get paragraphClass() {
    return style({
      marginTop: "1em",
      textAlign: this.isMobile ? undefined : "center",
      alignSelf: "center",
      maxWidth: "35rem"
    })
  }

  get logoClass() {
    return style({
      width: "10rem",
      marginBottom: "2rem"
    })
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  constructor(p: ThankYouPageViewProps) {
    super(p)

    // Allow exiting app without warning
    window.onbeforeunload = null
  }

  private renderMobile = this.renderMain

  private renderDesktop() {
    return (
      <CardLayout width="50rem" background={this.theme.background} headerBackgroundColor={this.theme.colors.primary}>
        {this.renderMain()}
      </CardLayout>
    )
  }

  private renderMain() {
    const { showReportButton, thankYouImage, resultMessage } = this.props

    const descriptionClass = style({
      textAlign: this.isMobile ? undefined : "center"
    })

    const imageClass = style({
      width: "15rem",
      alignSelf: "center",
      marginTop: "1rem",
      marginBottom: "3.5rem",
      opacity: "0.75"
    })

    return (
      <div>
        <div className={this.outerContainerClass}>
          {this.renderHeader()}
          <div className={this.contentClass}>
            <img src={thankYouImage} className={imageClass} />
            <Typography className={descriptionClass} component="h1" variant="h3">
              {this.txt("title")}
            </Typography>
            {resultMessage && (
              <p className={this.paragraphClass}>
                <em>{resultMessage}</em>
              </p>
            )}
          </div>

          <div className={this.contentClass}>{showReportButton && this.renderReportButton()}</div>
        </div>
      </div>
    )
  }

  private renderHeader() {
    const { logo } = this.props

    return (
      <div className={this.headerClass}>
        <div className={this.logoContainerClass}>
          <img alt="Hintsa" className={this.logoClass} src={logo} />
        </div>
      </div>
    )
  }

  private renderReportButton() {
    const buttonClass = style({
      minHeight: "2.5em",
      marginLeft: "0 !important",
      padding: "0 1em !important"
    })

    const buttonContainerClass = style({
      display: "flex",
      justifyContent: "center",
      marginTop: "1em"
    })

    return (
      <div className={buttonContainerClass}>
        <Button className={buttonClass} fullWidth={false} onClick={this.props.onReport}>
          {this.txt("show_results")}
        </Button>
      </div>
    )
  }
}
