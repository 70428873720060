import Box from "@material-ui/core/Box"
import moment from "moment"
import posthog from "posthog-js"
import * as queryString from "query-string"
import shortid from "shortid"

import type { SurveyActions } from "app/surveys/modules/actions/SurveyActions"
import type {
  Answer,
  Consent,
  PlannedSurvey,
  Question,
  ReportKey,
  ScheduledSurvey,
  Survey,
  SurveyAnswerInstance,
  Survey as SurveyModel,
  SurveyResult,
  SurveyState
} from "app/surveys/modules/state/model/Model"
import { OrganisationSurveyTag } from "app/surveys/modules/state/model/Model"
import PrivacyBanner from "app/surveys_app/components/survey/banners/PrivacyBanner"
import type { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"
import { I18n } from "core/modules/localization/I18n"
import type { Doc, LocalizationState } from "core/modules/state/model/Model"
import { defaultDocumentId } from "core/modules/state/model/Model"
import type { AuthenticationActions } from "lib/authentication/modules/actions/AuthenticationActions"
import BlockDiv from "lib/ui/components/layout/BlockDiv"

import { LogicComponent } from "../../base/LogicComponent"
import PersonalDetailsPage, { type PersonalDetailsResults } from "../pages/personal_details/PersonalDetailsPage"
import QuestionsPage from "../pages/questions/QuestionsPage"
import { StartPage, type StartPageError } from "../pages/start/StartPage"
import ThankYouPage from "../pages/thank_you/ThankYouPage"

interface SearchParams {
  // Obfuscated email and token
  e?: string
  language?: string
  // Obfuscated meta data
  m?: string
  // Obfuscated survey info
  s?: string
  /** @deprecated */
  email?: string
  /** @deprecated */
  cc?: string
  /** @deprecated */
  id?: string
  /** @deprecated */
  token?: string
  /** @deprecated */
  openLink?: "1"
  /** @deprecated */
  survey?: string
}

type SurveyPhase =
  | "start"
  | "personalDetails"
  | "questions"
  | "thankYou"
  | "notFound"
  | "error"
  | "alreadyCompleted"
  | "surveyClosed"

interface State extends LogicComponentState {
  languageChanged?: boolean
  lastPageVisited?: number
  questionsAnsweredWithNoneOfTheAbove: { [key: string]: boolean }
  page?: number
  showLoader?: boolean
  shownSurveyPhase?: SurveyPhase
}

interface AnswerUpdate {
  options?: {
    id: string
    note?: string
  }[]
  question_id: number
}

interface ResultUpdate extends Partial<Doc> {
  answers: AnswerUpdate[]
  complete: boolean
}

export const surveysWithReport: ReportKey[] = [
  "hintsa",
  "wellbeing_test",
  "wellbeing_start",
  "wellbeing_end",
  "wellbeing_general",
  "wellbeing_pulse"
]

const surveysWithPrivacyBanner: ReportKey[] = [
  "hintsa",
  "wellbeing_test",
  "wellbeing_start",
  "wellbeing_end",
  "wellbeing_general",
  "wellbeing_pulse"
]

export default class SurveyApp extends LogicComponent<LogicComponentProps, State> {
  declare authenticationActions: AuthenticationActions
  declare surveyActions: SurveyActions

  get componentName(): string[] {
    return ["survey", "SurveyApp"]
  }

  get dependencies(): string[] {
    return ["AuthenticationActions", "SurveyActions"]
  }

  // Set to true if answer updates are not sent to server due to congestion.
  private questionUpdatesHalted = false

  constructor(props: LogicComponentProps) {
    super(props)

    this.state = { lastPageVisited: 0, questionsAnsweredWithNoneOfTheAbove: {} }

    this.setDefault<SurveyState>({} as any, "SurveyState")

    this.modelManager.events.on("transactionEnded", () => this.forceUpdate())

    document.title = this.txt("title")
  }

  componentDidMount() {
    const params = this.getParams()

    posthog.capture("survey_app_loaded")

    if (!this.validateParams(params)) {
      posthog.capture("invalid_parameters")
      this.logger.error("Invalid or missing parameters, cannot start survey.")

      if (!location.search) {
        window.location.href = "https://www.hintsa.com"
      }

      return
    }

    this.startApp(params)
  }

  render() {
    return (
      <Box display="flex" flexDirection="column" height="100%" minHeight="100vh">
        {this.renderOverlayItems()}
        {this.renderBanner()}
        {this.renderContent()}
      </Box>
    )
  }

  private renderOverlayItems() {
    if (!this.state.showLoader) return null

    return <BlockDiv showProgress progressTimeout={this.appConfig.spinnerDelay} />
  }

  private renderBanner() {
    if (this.isAnonymousSurvey()) return null

    const surveyState = this.docDefault<SurveyState>("SurveyState")
    const survey = this.doc<SurveyModel>(surveyState?.surveyId, "Survey")
    const reportKey = survey?.report_key?.toLowerCase()

    return reportKey && surveysWithPrivacyBanner.includes(reportKey) ? <PrivacyBanner /> : null
  }

  private renderContent() {
    if (!this.state.shownSurveyPhase) return null

    const language = this.docDefault<LocalizationState>("LocalizationState")?.language || I18n.defaultLanguage
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    const survey = this.doc<SurveyModel>(surveyState?.surveyId, "Survey")
    const organisationSurvey = this.doc<ScheduledSurvey>(surveyState?.organisationSurveyId, "OrganisationSurvey")

    // Ensure we have survey if required
    const pagesWithoutSurvey = ["notFound", "error", "alreadyCompleted", "surveyClosed"]
    const hasSurvey = !!(survey && organisationSurvey)
    if (!pagesWithoutSurvey.includes(this.state.shownSurveyPhase) && !hasSurvey) {
      return null
    }

    switch (this.state.shownSurveyPhase) {
      case "error":
      case "notFound":
      case "alreadyCompleted":
      case "surveyClosed":
        return this.renderPageStartWithError(this.state.shownSurveyPhase)
      case "questions":
        return this.renderPageQuestions(survey!)
      case "start":
        return this.renderPageStart(survey!, organisationSurvey!)
      case "thankYou":
        return this.renderPageThankYou(survey!, language)
      case "personalDetails":
        return this.renderPagePersonalDetails(survey!, organisationSurvey!, language)
      default:
        return null
    }
  }

  renderPagePersonalDetails(survey: Survey, organisationSurvey: ScheduledSurvey, language: string) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    return (
      <PersonalDetailsPage
        organisationSurvey={organisationSurvey}
        survey={survey}
        maxPage={surveyState?.maxPage ?? 0}
        language={language}
        onDone={this.onPersonalDetailsNext}
        onPrevious={this.onPersonalDetailsPrevious}
      />
    )
  }

  renderPageThankYou(survey: Survey, language: string) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")!
    const surveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")!

    return (
      <ThankYouPage
        survey={survey}
        language={language}
        showConclusion={!surveyState?.isOpenLinkSurvey}
        surveyResult={surveyResult}
      />
    )
  }

  renderPageStart(survey: Survey, organisationSurvey: ScheduledSurvey) {
    return (
      <StartPage
        showConsent={!this.isAnonymousSurvey()}
        organisationSurvey={organisationSurvey}
        survey={survey}
        onEmailValidationCheck={this.isEmailValidationNeeded}
        onStart={this.onStartSurvey}
        onValidateEmailToken={this.onValidateEmailToken}
      />
    )
  }

  renderPageQuestions(survey: Survey) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")!
    const surveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")

    const page = this.state.page ?? 0
    const lastQuestionToShow = this.getLastQuestionIndexForPage(page)
    const maxQuestionsToShow = survey.questions.length
    const showDone = !this.arePersonalDetailsShown() && lastQuestionToShow === maxQuestionsToShow - 1

    return (
      <QuestionsPage
        survey={survey}
        surveyResult={surveyResult}
        lastAnsweredQuestionIndex={surveyState.lastAnsweredQuestionIndex ?? -1}
        firstQuestionToShow={this.getFirstQuestionIndexForPage(page)}
        lastQuestionToShow={lastQuestionToShow}
        page={page}
        maxPage={surveyState.maxPage ?? 0}
        onAnswer={this.onQuestionAnswered}
        onQuestionAnswerNoneOfTheAbove={this.onQuestionAnswerNoneOfTheAbove}
        onOptionSelected={this.onQuestionOptionSelected}
        onOptionsSelected={this.onQuestionOptionsSelected}
        onDone={showDone ? this.onDone : undefined}
        onPrevious={page > 0 ? this.onPreviousQuestionPage : undefined}
        onNext={showDone ? undefined : this.onNextQuestionPage}
      />
    )
  }

  renderPageStartWithError(error: StartPageError) {
    return <StartPage error={error} showConsent={!this.isAnonymousSurvey()} />
  }

  private getParams(): SearchParams {
    const params = queryString.parse(location.search)

    const results = {}
    for (const key of Object.keys(params)) {
      results[key] = Array.isArray(params[key]) ? params[key][0] : params[key]
    }

    return results
  }

  private validateParams(params: SearchParams) {
    if (params.s) return true

    if (params.email && !params.token) {
      posthog.capture("token_missing")
      this.logger.error("Token missing from querystring")
      return
    }

    if (params.email && !params.id) {
      posthog.capture("survey_id_missing")
      this.logger.error("Survey id missing from querystring")
      return
    }

    if (params.email && !params.language) {
      posthog.capture("language_missing")
      this.logger.error("Language missing from querystring")
      return
    }

    if (params.email && params.openLink === "1") {
      posthog.capture("cannot_combine_openlink_with_email")
      this.logger.error("Cannot combine openLink survey with email")
      return
    }

    if (!params.email && !params.cc) {
      posthog.capture("no_email_or_company_code")
      this.logger.error("No email or company code provided")
      return
    }

    if (params.openLink === "1" && !params.survey) {
      posthog.capture("no_survey_for_openlink")
      this.logger.error("No survey provided for openLink survey")
      return
    }

    if (params.openLink === "1" && !params.cc) {
      posthog.capture("no_company_for_openlink")
      this.logger.error("No company provided for openLink survey")
      return
    }

    return true
  }

  private async startApp(params: SearchParams) {
    posthog.capture("start_app")

    if (params.language) this.onSetLanguage(params.language)

    // Require either the new obfuscated survey info or deprecated id
    if (!params.s && !params.id && !params.survey) return

    this.showLoader()

    const isOpenLinkSurvey = !params.e && !params.email

    try {
      // Authenticate if credentials are provided
      if (params.e) {
        await this.authenticationActions.loginWithObfuscatedCredentials(params.e)
      } else if (params.email && params.token) {
        await this.authenticationActions.loginWithToken(params.token, params.email)
      }
      // @TODO: Verify email?

      const unifiedSurvey = await this.surveyActions.getDocument<PlannedSurvey>(
        {
          surveyParams: params.s,
          surveyMeta: params.m,

          // Deprecated open link flow
          id: params.survey,
          companyCode: params.cc,

          // Deprecated email flow
          plannedSurveyId: params.id
        },
        "UnifiedSurvey"
      )
      if (!unifiedSurvey) return

      this.handleDemoUsers(unifiedSurvey.organisation_survey)

      const survey = this.doc<Survey>(unifiedSurvey.survey.id, "Survey")!

      const organisationSurveyId = unifiedSurvey.organisation_survey.id!.toString()
      let lastAnsweredQuestionIndex = -1
      let surveyResultId: string | undefined = undefined

      // Load results if we have an authenticated user
      if (!isOpenLinkSurvey) {
        const results = await this.loadIncompleteSurveyResults(organisationSurveyId, survey)

        lastAnsweredQuestionIndex = results.lastAnsweredQuestionIndex
        surveyResultId = results.surveyResultId
      }

      const isSurveyStarted = !!surveyResultId
      const hasAnsweredQuestions = lastAnsweredQuestionIndex > -1
      const hasAnsweredAllQuestions = lastAnsweredQuestionIndex >= survey.questions.length - 1
      const maxPage = Math.max(0, this.getQuestionCategories(survey).length - 1)

      // Find the page with the first unanswered question or last page if all questions have been answered
      const page = hasAnsweredAllQuestions
        ? maxPage
        : this.getPageNumberForQuestionByCategory(lastAnsweredQuestionIndex + 1, survey!)

      let surveyPhase: SurveyPhase = "questions"
      if (hasAnsweredQuestions) {
        surveyPhase = "questions"
      } else if (isSurveyStarted) {
        posthog.capture("continue_existing_survey")
      } else {
        surveyPhase = "start"
      }

      this.updateDefault<SurveyState>("SurveyState", {
        companyCode: params.cc,
        isOpenLinkSurvey,
        surveyId: survey.id,
        surveyMeta: params.m,
        surveyParams: params.s,
        surveyResultId,
        organisationSurveyId,
        lastAnsweredQuestionIndex,
        maxPage
      })

      this.setState({ shownSurveyPhase: surveyPhase, page, lastPageVisited: page }, () =>
        posthog.capture("$pageview", { $current_url: `/${surveyPhase}` })
      )

      document.title = this.txt(survey.title)
    } catch (e) {
      this.handleError(e)
    } finally {
      this.hideLoader()
    }
  }

  private arePersonalDetailsShown(): boolean {
    const personalDetailsTags: string[] = [
      OrganisationSurveyTag.AskUserName,
      OrganisationSurveyTag.SelectTeam,
      OrganisationSurveyTag.SelectTags
    ]

    return !!this.getOrganisationSurveyTags()?.some(tag => personalDetailsTags.includes(tag))
  }

  private isAnonymousSurvey(): boolean {
    const organisationSurveyTags = this.getOrganisationSurveyTags()

    const isOpenLinkSurvey = organisationSurveyTags?.includes(OrganisationSurveyTag.OpenLinkSurvey)
    if (!isOpenLinkSurvey) return false

    const isAnonymousSurvey = organisationSurveyTags?.includes(OrganisationSurveyTag.AnonymousSurvey)
    if (isAnonymousSurvey) return true

    // Survey is considered anonymous also if it doesn't ask for user email or name
    const nonAnonymousTags: string[] = [OrganisationSurveyTag.AskUserEmail, OrganisationSurveyTag.AskUserName]
    return !organisationSurveyTags?.some(tag => nonAnonymousTags.includes(tag))
  }

  private handleDemoUsers(organisationSurvey: ScheduledSurvey) {
    if (organisationSurvey.organisation_name?.toLowerCase().includes("[demo]")) {
      posthog.identify(`[test]_${shortid.generate()}`, { testUser: true })
    }
  }

  private async loadIncompleteSurveyResults(organisationSurveyId: string | number | undefined, survey: SurveyModel) {
    const surveyResultsView = await this.surveyActions.getView<SurveyResult>("SurveyResults", {
      organisationSurveyId,
      incomplete: true
    })

    let lastAnsweredQuestionIndex = -1
    const surveyResult = surveyResultsView.documents[0]
    const instance = surveyResult?.instances?.[0]

    if (!!instance?.answers?.length) {
      const answeredQuestions: string[] = []

      // Build list of answered question ids
      for (const answer of instance.answers) {
        const question = this.getQuestionForAnswer(answer, survey)

        if (question) answeredQuestions.push(question.id!)
      }

      if (answeredQuestions.length >= survey.questions.length) {
        // All questions have been answered
        lastAnsweredQuestionIndex = survey.questions.length - 1
      } else {
        // Get first non-optional unanswered question index
        const firstUnansweredQuestionIndex = survey.questions.findIndex(
          question => !answeredQuestions.includes(question.id!) && question.min_required_options !== 0
        )
        lastAnsweredQuestionIndex = firstUnansweredQuestionIndex - 1
      }

      this.logger.info("Previous survey answers exist. Last answered question index:", lastAnsweredQuestionIndex)
      posthog.capture("continue_previous_survey")
    }

    return { lastAnsweredQuestionIndex, surveyResultId: surveyResult?.id }
  }

  private onSetLanguage = (language: string) => {
    posthog.capture("set_language", { language })

    this.updateDefault<LocalizationState>("LocalizationState", { language })
    this.setState({ languageChanged: true })
  }

  private onStartSurvey = async () => {
    posthog.capture("start_survey")

    this.showLoader()

    const surveyState = this.docDefault<SurveyState>("SurveyState")
    const survey = this.doc<SurveyModel>(surveyState?.surveyId, "Survey")
    if (!surveyState || !survey?.questions?.length) {
      this.hideLoader()

      return alert("Error: No questions in this survey")
    }

    posthog.capture("survey_started", { survey_id: survey.id, survey_title: survey.title.en })

    if (surveyState.isOpenLinkSurvey) {
      posthog.capture("open_link_survey")

      // Open link survey doesn't set answers to backend until completed - use local document
      const surveyResultId = defaultDocumentId
      const surveyResult = {
        __type: "SurveyResult",
        id: surveyResultId,
        instances: [
          {
            date: moment.utc(),
            answers: survey.questions.map(q => ({ question_id: Number(q.id), options: [] })),
            title: survey.title.string
          }
        ],
        survey: { __type: "Survey", id: survey.id }
      }
      this.set(surveyResult)

      const shownSurveyPhase = "questions"
      this.setState({ shownSurveyPhase }, () => {
        posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` })
      })

      this.updateDefault<SurveyState>("SurveyState", { surveyResultId })

      this.hideLoader()

      return
    }

    posthog.capture("not_open_link_survey")

    await this.surveyActions.createDocument<Consent>({
      __type: "Consent",
      scope: "org_surveys",
      consent_type: "terms_of_service",
      organisation_survey_id: Number(surveyState.organisationSurveyId)
    })

    const existingSurveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")
    if (!existingSurveyResult) {
      try {
        const surveyResult = await this.surveyActions.createDocument<SurveyResult>(
          { __type: "SurveyResult", instances: [] },
          {
            companyCode: surveyState.companyCode,
            organisationSurveyId: surveyState.organisationSurveyId,
            surveyMeta: surveyState.surveyMeta,
            surveyParams: surveyState.surveyParams
          }
        )
        this.updateDefault<SurveyState>("SurveyState", { surveyResultId: surveyResult!.id })

        const shownSurveyPhase = "questions"
        this.setState({ shownSurveyPhase }, () =>
          posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` })
        )
      } catch {
        // TBD Error messaging
        posthog.capture("getting_survey_results_failed")
        alert("Getting survey results failed, please reload the page.")
      }
    }

    this.hideLoader()
  }

  private handleError(error: any) {
    this.logger.warning(`Error: ${error?.error ?? error}`)
    posthog.capture("error", { error: error?.error ?? error })

    let shownSurveyPhase: SurveyPhase = "error"

    switch (error.error) {
      case "already_answer":
        posthog.capture("survey_already_answered")
        shownSurveyPhase = "alreadyCompleted"
        break

      case "not_found":
        posthog.capture("survey_not_found")
        shownSurveyPhase = "notFound"
        break

      case "survey_closed":
        posthog.capture("survey_already_closed")
        shownSurveyPhase = "surveyClosed"
        break

      default:
        // nothing to do, default values are generic error
        break
    }

    this.updateDefault<SurveyState>("SurveyState", {
      surveyId: undefined,
      surveyResultId: undefined,
      organisationSurveyId: undefined,
      isOpenLinkSurvey: undefined
    })

    this.setState({ shownSurveyPhase }, () => posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` }))
  }

  private getOrganisationSurvey = () => {
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    if (!surveyState?.organisationSurveyId) return

    return this.doc<ScheduledSurvey>(surveyState.organisationSurveyId, "OrganisationSurvey")
  }

  private getOrganisationSurveyTags = () => {
    return this.getOrganisationSurvey()?.organisation_survey_tags
  }

  private getQuestionCategories = (survey: SurveyModel) => [...new Set(survey.questions.map(q => q.category?.en))]

  private getQuestionForAnswer(answer: Answer, survey: SurveyModel): Question | undefined {
    const answerOptionIds = answer.options!.map(o => String(o.id))

    return survey.questions.find(q => q.options.some(o => answerOptionIds.includes(String(o.id))))
  }

  private getQuestionsForPage(survey: SurveyModel, page: number): Question[] {
    const categories = this.getQuestionCategories(survey)

    return survey.questions.filter(q => q.category?.en === categories[page])
  }

  private getPageNumberForQuestionByCategory(questionOrIndex: Question | number, survey: SurveyModel) {
    if (questionOrIndex === -1) return 0

    const question = typeof questionOrIndex === "number" ? survey.questions[questionOrIndex] : questionOrIndex

    return this.getQuestionCategories(survey).indexOf(question.category?.en ?? "")
  }

  private getFirstQuestionIndexForPage(page: number) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")!

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")!
    const category = this.getQuestionCategories(survey)[page]
    const categoriesOnly = survey.questions.map(q => q.category?.en)

    return categoriesOnly.indexOf(category)
  }

  private getLastQuestionIndexForPage(page: number) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")!

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")!
    const category = this.getQuestionCategories(survey)[page]
    const categoriesOnly = survey.questions.map(q => q.category?.en)

    return categoriesOnly.lastIndexOf(category)
  }

  private getLastAnsweredQuestionIndexForPage(page: number, answers: Answer[] | AnswerUpdate[]) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")!

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")!
    const questions = this.getQuestionsForPage(survey, page)
    const answeredQuestions = answers.map(a => (a.question_id ?? "").toString())
    const firstUnansweredIndex = questions.findIndex(q => !answeredQuestions.includes((q.id ?? "").toString()))

    return Math.max(firstUnansweredIndex - 1, -1)
  }

  private onDone = async () => {
    this.showLoader()

    const surveyState = this.docDefault<SurveyState>("SurveyState")!
    const surveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")!
    const currentAnswer: SurveyAnswerInstance = surveyResult.instances[0]
    const answers = currentAnswer.answers.map(this.resultAnswerToUpdate)
    const update: ResultUpdate = {
      __type: "SurveyResult",
      complete: true,
      answers
    }

    posthog.capture("survey_completed")

    this.logger.info("Sending final results", update)

    try {
      if (surveyState.isOpenLinkSurvey) {
        const results = await this.surveyActions.createDocument<SurveyResult, ResultUpdate>(
          {
            ...update,
            email: surveyState.email,
            first_name: surveyState.firstName,
            last_name: surveyState.lastName,
            selected_team: surveyState.selectedTeam,
            selected_tags: (surveyState.selectedTags ?? []).join(",")
          },
          {
            companyCode: surveyState.companyCode,
            organisationSurveyId: surveyState.organisationSurveyId,
            surveyMeta: surveyState.surveyMeta,
            surveyParams: surveyState.surveyParams
          }
        )

        this.updateDefault<SurveyState>("SurveyState", { surveyResultId: results!.id })
      } else {
        await this.surveyActions.updateDocument<SurveyResult, ResultUpdate>(
          {
            ...update,
            selected_tags: (surveyState.selectedTags ?? []).join(",")
          },
          { id: surveyResult.id }
        )
      }
    } catch (error) {
      posthog.capture("sending_survey_results_failed", { error })

      this.logger.error("Failed to send survey results", error)
      window.setTimeout(() => alert("Failed to send survey results"), 0)

      return
    } finally {
      this.hideLoader()
    }

    this.setState({ shownSurveyPhase: "thankYou" }, () => posthog.capture("$pageview", { $current_url: "/thankYou" }))
  }

  private showLoader() {
    this.setState({ showLoader: true })
  }

  private hideLoader() {
    this.setState({ showLoader: false })
  }

  private scrollButtonsIntoView() {
    setTimeout(() => {
      const buttonContainer = document.getElementById("buttonContainer")
      buttonContainer?.scrollIntoView({ block: "end", behavior: "auto" })
    }, 0)
  }

  private resultAnswerToUpdate = (answer: Answer): AnswerUpdate => ({
    question_id: answer.question_id,
    options: answer.options?.map(({ id, note }) => ({ id, note }))
  })

  private onQuestionAnswerNoneOfTheAbove = (questionId: string) => {
    const { questionsAnsweredWithNoneOfTheAbove } = this.state
    questionsAnsweredWithNoneOfTheAbove[questionId] = true

    this.setState({ questionsAnsweredWithNoneOfTheAbove })
  }

  private onQuestionAnswered = (questionId: string, options: Map<string, string | boolean>) => {
    const { questionsAnsweredWithNoneOfTheAbove } = this.state

    const surveyState = this.docDefault<SurveyState>("SurveyState")!

    const result = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")!
    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")!
    const currentAnswer: SurveyAnswerInstance = result.instances[0]

    const questionIndex = survey.questions.findIndex(q => q.id === questionId)
    if (questionIndex === -1) {
      this.logger.warning("Question not found in survey", { questionId, surveyId: survey.id })
      return
    }

    posthog.capture("question_answered", { questionId, questionIndex })

    const question = survey.questions[questionIndex]
    this.logger.info("Question answered", { title: question.title, options })

    // Remove existing answer for current question
    const answerIndex = currentAnswer.answers.findIndex(a => a.question_id === Number(questionId))
    if (answerIndex > -1) currentAnswer.answers.splice(answerIndex, 1)

    // Add new answer, simple selections' option value is true, open field's is string
    const optionIds = Array.from(options.keys())

    const answered = optionIds.map(id =>
      typeof options.get(id) === "string" ? { id, note: options.get(id) as string } : { id }
    )

    currentAnswer.answers.push({ question_id: Number(questionId), options: answered as any })

    const answers = currentAnswer.answers.map(this.resultAnswerToUpdate)
    const update: ResultUpdate = {
      __type: "SurveyResult",
      complete: false,
      answers
    }

    this.logger.info("Updating survey results", update)

    // Update the result document
    this.set(result)

    // Send answer to backend
    this.updateQuestionToServer(update, result).then()

    // Make sure the last answered question state is accurate
    const answeredQuestionIndex = this.getLastAnsweredQuestionIndexForPage(this.state.page ?? 0, answers)
    const lastAnsweredQuestionIndex = this.skipOptionalQuestions(
      survey,
      Math.max(surveyState.lastAnsweredQuestionIndex ?? 0, questionIndex, answeredQuestionIndex)
    )

    this.updateDefault<SurveyState>("SurveyState", { lastAnsweredQuestionIndex })

    if (questionsAnsweredWithNoneOfTheAbove[question.id]) {
      questionsAnsweredWithNoneOfTheAbove[question.id] = false
      this.setState({ questionsAnsweredWithNoneOfTheAbove })
    }
  }

  private onQuestionOptionSelected = (questionId: string, optionId: string) => {
    this.onQuestionAnswered(questionId, new Map([[optionId, true]]))
  }

  private onQuestionOptionsSelected = (questionId: string, optionIds: string[]) => {
    const options = new Map(optionIds.map(optionId => [optionId, true]))
    this.onQuestionAnswered(questionId, options)
  }

  private async updateQuestionToServer(update: ResultUpdate, result: SurveyResult) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")!

    // Open link survey sent to server only in the end
    if (surveyState.isOpenLinkSurvey) return

    if (this.questionUpdatesHalted) {
      this.logger.warning("Not sending question updates due to congestion")
      return
    }

    try {
      this.haltQuestionUpdates()
      await this.surveyActions.updateDocument(update, { id: result.id, skipLocalUpdates: true })
    } finally {
      this.resumeQuestionUpdates()
    }
  }

  private haltQuestionUpdates = () => {
    this.questionUpdatesHalted = true
  }

  private resumeQuestionUpdates = () => {
    this.questionUpdatesHalted = false
  }

  private onPersonalDetailsNext = (results: PersonalDetailsResults) => {
    this.updateDefault<SurveyState>("SurveyState", { ...results })

    this.onDone()
  }

  private onPersonalDetailsPrevious = () => {
    const shownSurveyPhase = "questions"
    this.setState({ shownSurveyPhase }, () => {
      posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` })
    })
  }

  private isEmailValidationNeeded = async (email?: string) => {
    // we send the email to the server to check for duplicates
    // if no email found, continue with the survey; if the email already exists
    // the user needs to enter a token value emailed to them to continue
    this.showLoader()

    try {
      const { companyCode, organisationSurveyId, surveyParams } = this.docDefault<SurveyState>("SurveyState") || {}
      if (!email || !(surveyParams || (companyCode && organisationSurveyId))) return false

      this.updateDefault<SurveyState>("SurveyState", { email })

      const result = surveyParams
        ? await this.surveyActions.validateEmailCheck(email, surveyParams)
        : await this.surveyActions.validateEmailCheck(email, companyCode!, organisationSurveyId)

      let shownSurveyPhase: SurveyPhase | undefined

      // Email not found - no need for validation
      if (result.status === 204) {
        this.onStartSurvey()

        return false
      }

      // Email found
      if (result.status === 200) {
        const data = await result.json()

        // we've returned an email that we found in the database, so check this survey hasn't
        // already been completed, then ask the user to enter the token just sent to them
        if (data["status"] === "already_answer") {
          shownSurveyPhase = "alreadyCompleted"
        } else if (data["status"] === "survey_closed") {
          shownSurveyPhase = "surveyClosed"
        } else if (data["email"]) {
          posthog.capture("email_validation_needed")

          return true
        } else {
          shownSurveyPhase = "error"
        }
      }

      this.setState({ shownSurveyPhase }, () => posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` }))

      return false
    } catch (e) {
      this.handleError(e)

      return false
    } finally {
      this.hideLoader()
    }
  }

  private onValidateEmailToken = async (emailToken?: string): Promise<boolean> => {
    this.updateDefault<SurveyState>("SurveyState", { emailToken })

    const surveyState = this.docDefault<SurveyState>("SurveyState")
    if (!surveyState?.email || !emailToken) return false

    return this.isValidEmailToken(surveyState?.email, emailToken)
  }

  private isValidEmailToken = async (email: string, token: string) => {
    posthog.capture("attempting_email_validation")

    this.showLoader()

    try {
      const result = await this.authenticationActions.loginWithToken(token, email, false)

      if (result.status === 200) return true
    } catch (e) {
      posthog.capture("email_validation_failed", { error: e })
    } finally {
      this.hideLoader()
    }

    return false
  }

  private onPreviousQuestionPage = () => {
    this.scrollButtonsIntoView()

    const page = this.state.page ?? 0
    if (page > 0) {
      posthog.capture("previous_page", { page: `page: ${page - 1}` })

      this.setState({ page: page - 1 })
    }
  }

  private onNextQuestionPage = () => {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const page = this.state.page ?? 0
    const maxPage = surveyState?.maxPage ?? 0
    if (page >= maxPage) {
      if (this.arePersonalDetailsShown()) {
        const shownSurveyPhase: SurveyPhase = "personalDetails"
        this.setState({ shownSurveyPhase }, () => {
          posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` })
        })
      }

      return
    }

    posthog.capture("next_page", { page: `page: ${page! + 1}` })

    this.updateState(state => {
      const surveyState = this.docDefault<SurveyState>("SurveyState")!

      state.page!++

      const result = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")!
      const currentAnswer: SurveyAnswerInstance = result.instances[0]
      if (result) {
        const answeredQuestionIndex = this.getLastAnsweredQuestionIndexForPage(
          state.page!,
          currentAnswer?.answers ?? []
        )
        this.set(
          Object.assign({}, surveyState, {
            lastAnsweredIndex: Math.max(surveyState.lastAnsweredQuestionIndex ?? 0, answeredQuestionIndex)
          })
        )
      }

      if (state.page! > state.lastPageVisited!) {
        window.scrollTo(0, 0)
        state.lastPageVisited = state.page
      }
    })
  }

  private skipOptionalQuestions(survey: SurveyModel, questionIndex: number) {
    const { questionsAnsweredWithNoneOfTheAbove } = this.state

    for (; questionIndex < survey.questions.length - 1; questionIndex++) {
      const question = survey.questions[questionIndex + 1]

      if (
        question?.min_required_options !== 0 ||
        (question.kind === "checkbox" && !questionsAnsweredWithNoneOfTheAbove[question.id])
      )
        break
    }

    return questionIndex
  }
}
