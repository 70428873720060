import type { Survey, SurveyResult } from "app/surveys/modules/state/model/Model"
import type { LogicComponentProps } from "core/components/base/LogicComponent"
import { COBL_CATEGORIES } from "lib/utils/report/WellbeingCommonReportUtils"

import { LogicComponent } from "../../../base/LogicComponent"
import QuestionsPageView from "./QuestionsPageView"

interface QuestionsPageProps extends LogicComponentProps {
  firstQuestionToShow: number
  lastAnsweredQuestionIndex: number
  lastQuestionToShow: number
  maxPage: number
  onAnswer: (questionId: string, answers: Map<string, string | boolean>) => void
  onQuestionAnswerNoneOfTheAbove: (questionId: string) => void
  onDone?: () => void
  onNext?: () => void
  onOptionSelected: (questionId: string, optionId: string) => void
  onOptionsSelected: (questionId: string, optionIds: string[]) => void
  onPrevious?: () => void
  page: number
  survey: Survey
  surveyResult?: SurveyResult
}

export default class QuestionsPage extends LogicComponent<QuestionsPageProps> {
  get background() {
    const background = this.categoryPrefix ? `header-${this.categoryPrefix}.jpg` : undefined

    return `${this.appConfig.imagesRoot}/${background}`
  }

  get categoryPrefix() {
    const { firstQuestionToShow, survey } = this.props
    const firstQuestion = survey.questions[firstQuestionToShow]

    return COBL_CATEGORIES.find(prefix => firstQuestion?.key?.startsWith(prefix))
  }

  get icon() {
    const icon = this.categoryPrefix ? `cobl_categories/icon_${this.categoryPrefix}_b.svg` : undefined

    return icon && `${this.appConfig.imagesRoot}/${icon}`
  }

  render() {
    super.render()

    return (
      <QuestionsPageView
        lastAnsweredQuestionIndex={this.props.lastAnsweredQuestionIndex}
        survey={this.props.survey}
        firstQuestionToShow={this.props.firstQuestionToShow}
        lastQuestionToShow={this.props.lastQuestionToShow}
        surveyResult={this.props.surveyResult}
        page={this.props.page}
        maxPage={this.props.maxPage}
        background={this.background}
        icon={this.icon}
        logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
        onPrevious={this.props.onPrevious}
        onNext={this.props.onNext}
        onAnswer={this.props.onAnswer}
        onQuestionAnswerNoneOfTheAbove={this.props.onQuestionAnswerNoneOfTheAbove}
        onOptionSelected={this.props.onOptionSelected}
        onOptionsSelected={this.props.onOptionsSelected}
        onDone={this.props.onDone}
      />
    )
  }
}
