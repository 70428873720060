import type { ComponentType, PropsWithChildren } from "react"

import { useTheme } from "core/modules/themes/useTheme"
import { useMobile } from "lib/hooks/useMobile"
import CardLayout from "lib/ui/components/layout/CardLayout"

export function withCardLayout<P extends object>(WrappedComponent: ComponentType<P>) {
  return function WrappedWithCardLayout(props: PropsWithChildren<P>) {
    const theme = useTheme()
    const isMobile = useMobile()

    if (isMobile) return <WrappedComponent {...props} />

    return (
      <CardLayout background={theme.background} headerBackgroundColor={theme.colors.primary} width="50rem">
        <WrappedComponent {...props} />
      </CardLayout>
    )
  }
}
