import type { ViewParameters as CoreViewParameters } from "core/modules/actions/CoreActions"
import type { RestApiCallParameters } from "core/modules/api/CoreApi"
import type { Localization } from "core/modules/localization/Localization"
import type { Doc } from "core/modules/state/model/Model"
import type { ModelManager } from "core/modules/state/model/ModelManager"
import type { ViewContainerManager } from "core/modules/state/model/ViewContainerManager"
import type { Store } from "core/modules/store/Store"

import { BaseModule } from "../../controller/Module"
import { SurveysApi } from "../api/SurveysApi"

export interface ViewParameters extends CoreViewParameters {
  page?: number
  documentsPerPage?: number
  [id: string]: any
}

export interface SurveyActions {
  getDocument<T extends Doc>(id: string | Partial<T>, type: string): Promise<T | undefined>
  updateDocument<Out extends Doc, In extends Partial<Doc> = Partial<Out>>(
    document: In,
    parameters?: Partial<RestApiCallParameters<In>>
  ): Promise<Out | undefined>
  createDocument<Out extends Doc, In extends Partial<Doc> = Partial<Out>>(
    document: In,
    parameters?: Partial<RestApiCallParameters<In>>
  ): Promise<Out | undefined>
  removeDocument<T extends Doc>(document: string | T, parameters?: RestApiCallParameters): Promise<Response | undefined>
  getView<T extends Doc>(viewName: string, parameters?: Omit<ViewParameters, "type">): Promise<ViewContainerManager<T>>
  verifyEmail(email: string): Promise<Response>
  validateEmailCheck(email: string, paramsOrcompanyCode: string, organisationSurveyId?: string): Promise<Response>
  validateEmailToken(email: string, token: string): Promise<Response>
  updateReportExtra(reportId: string, answerId: string, reportExtra?: Record<string, unknown>): Promise<Response>
}

export class SurveyActionsModule extends BaseModule implements SurveyActions {
  declare localization: Localization
  declare modelManager: ModelManager
  declare store: Store
  declare surveysApi: SurveysApi

  get moduleName() {
    return "SurveyActions"
  }

  get dependencies() {
    return ["Localization", "ModelManager", "Store", "SurveysApi"]
  }

  getDocument<T extends Doc>(id: string | Partial<T>, documentType: string): Promise<T | undefined> {
    const params = typeof id === "string" ? { id } : { ...id }

    return this.coreActions.getDocument<T>(this.surveysApi, {
      ...params,
      type: documentType,
      acceptLanguage: this.acceptLanguage
    })
  }

  getView<T extends Doc>(
    viewName: string,
    parameters?: Omit<ViewParameters, "type">
  ): Promise<ViewContainerManager<T>> {
    return this.coreActions.getView<T>(this.surveysApi, {
      ...parameters,
      acceptLanguage: parameters?.acceptLanguage || this.acceptLanguage,
      type: viewName
    })
  }

  createDocument<Out extends Doc, In extends Partial<Doc> = Partial<Out>>(
    document: In,
    parameters?: Partial<RestApiCallParameters<In>>
  ): Promise<Out | undefined> {
    return this.coreActions.createDocument<Out, In>(this.surveysApi, {
      ...parameters,
      acceptLanguage: parameters?.acceptLanguage || this.acceptLanguage,
      type: parameters?.type || document.__type!,
      body: document
    })
  }

  removeDocument<T extends Doc>(
    document: string | T,
    parameters: RestApiCallParameters
  ): Promise<Response | undefined> {
    parameters ??= {} as RestApiCallParameters
    parameters.acceptLanguage ||= this.acceptLanguage

    if (typeof document === "string") {
      parameters.id = document
    } else {
      parameters.id = document.id
      parameters.type ||= document.__type
    }

    return this.coreActions.removeDocument(this.surveysApi, parameters)
  }

  updateDocument<Out extends Doc, In extends Partial<Doc> = Partial<Out>>(
    document: In,
    parameters: Partial<RestApiCallParameters<In>>
  ): Promise<Out | undefined> {
    return this.coreActions.updateDocument<Out, In>(this.surveysApi, document, {
      ...parameters,
      acceptLanguage: parameters.acceptLanguage || this.acceptLanguage,
      type: parameters.type || document.__type,
      body: document
    })
  }

  verifyEmail(email: string): Promise<Response> {
    const parameters: RestApiCallParameters<{ email: string }> = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      body: { email },
      type: "EmailVerify"
    }

    return this.surveysApi.restApiCall(parameters)
  }

  validateEmailCheck(email: string, paramsOrCompanyCode: string, organisationSurveyId?: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "create",
      body: { email },
      type: "EmailValidateCheck"
    }
    if (organisationSurveyId) {
      parameters.id = organisationSurveyId
      parameters.companyCode = paramsOrCompanyCode
    } else {
      parameters.surveyParams = paramsOrCompanyCode
    }

    return this.surveysApi.restApiCall(parameters)
  }

  validateEmailToken(emailAddress: string, tokenValue: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "create",
      email: emailAddress,
      magic_token: tokenValue,
      grant_type: "magic_token",
      search: [{ email: emailAddress }, { magic_token: tokenValue }, { grant_type: "magic_token" }],
      type: "EmailValidateToken"
    }

    return this.surveysApi.restApiCall(parameters)
  }

  updateReportExtra(reportId: string, answerId: string, reportExtra: Record<string, unknown>) {
    const parameters: RestApiCallParameters<{ report_extra: Record<string, unknown> }> = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      reportId,
      answerId,
      reportExtra: reportExtra,
      type: "SurveyResultsExtra",
      body: { report_extra: reportExtra }
    }

    return this.surveysApi.restApiCall(parameters)
  }

  private get acceptLanguage() {
    return this.localization.currentLanguage
  }
}
