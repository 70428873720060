import { QuestionKind } from "app/surveys/modules/state/model/Model"
import { Localization } from "core/modules/localization/Localization"
import { TranslatedString } from "core/modules/state/model/Model"

interface Answer {
  category?: TranslatedString
  options?: Array<{ points?: number; title?: TranslatedString }>
  question_key?: string
  question_meta?: Record<string, any>
}

export const getAnswerPoints = <T extends Answer>(answer: T) =>
  answer?.options
    ?.map(option => option?.points ?? 0)
    .reduce((total, points) => total + points, answer.question_key === "sr_practices" ? 1 : 0) ?? 0

export const getAnswerTitle = <T extends Answer>(answer: T, localization: Localization) =>
  answer?.options
    ?.map(option => option.title && localization.txt(option.title!))
    .filter(Boolean)
    .join(", ")

export const getAnswerMinPoints = (answer: Answer) => {
  if (answer.question_key === "fe_programme_lifestyle_change") return -1

  if (["fe_programme_improve_skillset", "fe_programme_reach_goal"].includes(answer.question_key!)) return -2

  return 1
}

export const getAnswerMaxPoints = (answer: Answer) => {
  if (answer.question_key === "fe_session_help_areas") return 7

  if (answer.question_key === "fe_programme_lifestyle_change") return 1

  if (answer.question_key === "fe_programme_recommend") return 10

  if (["fe_programme_improve_skillset", "fe_programme_reach_goal"].includes(answer.question_key!)) return 2

  return 5
}

export const getQuestionType = (answer: Answer): QuestionKind => {
  if (["fe_consent_contact", "fe_longterm_changes"].includes(answer.question_key!)) return "boolean"
  if (["fe_other_feedback", "fe_programme_recommend_why"].includes(answer.question_key!)) return "field"

  return "radio"
}

export const getScoreColorSetpointsForAnswer = (answer: Answer) => {
  if (getAnswerMinPoints(answer) === -2 && getAnswerMaxPoints(answer) === 2) {
    return {
      darkGreen: 2,
      lightGreen: 1,
      yellow: 0,
      orange: -1,
      red: -2
    }
  }

  if (answer.question_key === "fe_programme_lifestyle_change") {
    return {
      darkGreen: 1,
      yellow: 0,
      red: -1
    }
  }
  if (answer.question_key === "fe_programme_recommend")
    return { darkGreen: 9, lightGreen: 8, yellow: 7, orange: 6, red: 4 }
  if (answer.question_key === "fe_session_help_areas") return { darkGreen: 1 }

  return { darkGreen: 5, lightGreen: 4, yellow: 3, orange: 2, red: 1 }
}
