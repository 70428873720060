import { stylesheet } from "typestyle"

import { appConfig } from "app/surveys_app/modules/config/appConfig"
import { mobileStyle } from "core/modules/themes/mobileStyle"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"

interface Props {
  customLogo?: string
  subtitle?: string
  title: string
}

export function StartPageHeader({ customLogo, subtitle, title }: Props) {
  return (
    <>
      <Grid className={styles.logos} container>
        <Grid xs={6}>
          <img alt="Hintsa logo" src={`${appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`} />
        </Grid>

        {customLogo && (
          <Grid className={styles.customLogo} xs={6}>
            <img alt="" src={customLogo} />
          </Grid>
        )}
      </Grid>

      <Typography className={styles.title} variant="heading">
        {title}
      </Typography>

      {subtitle && (
        <Typography className={styles.subtitle} variant="smallHeading">
          {subtitle}
        </Typography>
      )}
    </>
  )
}

const styles = stylesheet({
  logos: {
    alignItems: "center",
    $nest: {
      img: { height: "2.25rem", maxWidth: "100%", width: "auto" }
    }
  },

  customLogo: {
    textAlign: "right"
  },

  title: mobileStyle({
    fontSize: "2rem !important",
    lineHeight: "2.5rem !important"
  }),

  subtitle: mobileStyle({
    fontSize: "1.5rem !important",
    lineHeight: "2rem !important"
  })
})
