import { style } from "typestyle"

import type { ScheduledSurvey, Survey } from "app/surveys/modules/state/model/Model"
import { withCardLayout } from "app/surveys_app/components/survey/base/withCardLayout"
import { mobileStyle } from "core/modules/themes/mobileStyle"
import { useTheme } from "core/modules/themes/useTheme"
import Markdown from "lib/ui/components/markdown/Markdown"
import MarkdownConverter from "lib/ui/components/markdown/MarkdownConverter"
import { LanguageSelector } from "lib/ui/components/settings/LanguageSelector"

import { StartPageForm } from "./StartPageForm"
import { StartPageHeader } from "./StartPageHeader"
import { StartPageInfo } from "./StartPageInfo"
import { useStartPageData } from "./useStartPageData"

export type StartPageError = "alreadyCompleted" | "error" | "notFound" | "surveyClosed"

interface Props {
  error?: StartPageError
  onEmailValidationCheck?: (email: string) => Promise<boolean>
  onStart?: () => void
  onValidateEmailToken?: (token?: string) => Promise<boolean>
  organisationSurvey?: ScheduledSurvey
  showConsent: boolean
  survey?: Survey
}

export function StartPageView({
  error,
  onEmailValidationCheck,
  onStart,
  onValidateEmailToken,
  organisationSurvey,
  showConsent,
  survey
}: Props) {
  const { colors } = useTheme()

  const { askEmail, description, estimatedTime, hasReport, numberOfQuestions, subtitle, title } = useStartPageData(
    survey,
    organisationSurvey,
    error
  )

  return (
    <div className={containerClass}>
      <StartPageHeader customLogo={organisationSurvey?.logo} subtitle={subtitle} title={title} />

      <LanguageSelector />

      <StartPageInfo
        estimatedTime={estimatedTime}
        hasError={!!error}
        hasReport={hasReport}
        numberOfQuestions={numberOfQuestions}
      />

      <MarkdownConverter linkColor={colors.secondary.toString()} linksInNewWindow>
        <Markdown content={description} />
      </MarkdownConverter>

      {!error && (
        <StartPageForm
          allowedEmailDomains={organisationSurvey?.allowed_domains}
          showConsent={showConsent}
          showEmail={askEmail}
          onEmailValidationCheck={onEmailValidationCheck}
          onStart={onStart}
          onValidateEmailToken={onValidateEmailToken}
        />
      )}
    </div>
  )
}

export const StartPage = withCardLayout(StartPageView)

const containerClass = style({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  margin: "2.5rem",
  ...mobileStyle({ margin: "1.5rem", minWidth: "18rem" })
})
