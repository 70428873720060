import { OrganisationSurveyTag, type ScheduledSurvey, type Survey } from "app/surveys/modules/state/model/Model"
import { surveysWithReport } from "app/surveys_app/components/survey/base/Survey"
import { useTranslate, useTranslateWithBaseKey } from "core/modules/localization/localizationHooks"

export type StartPageError = "alreadyCompleted" | "error" | "notFound" | "surveyClosed"

export function useStartPageData(questionnaire?: Survey, organisationSurvey?: ScheduledSurvey, error?: StartPageError) {
  const translate = useTranslateWithBaseKey("components", "survey", "pages", "start", "StartPage")
  const translateString = useTranslate()

  const reportKey = questionnaire?.report_key?.toLowerCase()
  const askEmail = !!organisationSurvey?.organisation_survey_tags?.includes(OrganisationSurveyTag.AskUserEmail)
  const hasReport = !!reportKey && surveysWithReport.includes(reportKey)
  const numberOfQuestions = questionnaire?.questions?.length

  const title = error ? translate(`${error}_title`) : translateString(questionnaire?.title)

  let description: string
  if (error) {
    description = translate(`${error}_description`)
  } else {
    description = translateString(organisationSurvey?.description) || translateString(questionnaire?.description)
  }

  const subtitle =
    reportKey &&
    ["hintsa", "wellbeing_test", "wellbeing_start", "wellbeing_end", "wellbeing_general", "wellbeing_pulse"].includes(
      reportKey
    )
      ? translate("survey_subheading_personal_wellbeing")
      : undefined

  let estimatedTime: number | undefined
  if (
    reportKey &&
    ["hintsa", "wellbeing_test", "wellbeing_start", "wellbeing_end", "wellbeing_general"].includes(reportKey)
  ) {
    estimatedTime = 10
  }
  if (reportKey === "wellbeing_pulse") {
    estimatedTime = 5
  }

  return { askEmail, description, estimatedTime, hasReport, numberOfQuestions, subtitle, title }
}
