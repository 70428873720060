import CheckBox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import { useToggle } from "@uidotdev/usehooks"
import { useState } from "react"
import { style } from "typestyle"

import { useValidEmail } from "app/surveys_app/components/survey/pages/start/useValidEmail"
import { useTranslateWithBaseKey } from "core/modules/localization/localizationHooks"
import { mobileStyle } from "core/modules/themes/mobileStyle"
import { useTheme } from "core/modules/themes/useTheme"
import { useMobile } from "lib/hooks/useMobile"
import Button from "lib/ui/components/form/Button"
import TextField from "lib/ui/components/form/TextField"
import Markdown from "lib/ui/components/markdown/Markdown"
import MarkdownConverter from "lib/ui/components/markdown/MarkdownConverter"

import { EmailValidationDialog } from "./EmailValidationDialog"

interface Props {
  allowedEmailDomains?: string
  onEmailValidationCheck?: (email: string) => Promise<boolean>
  onStart?: () => void
  onValidateEmailToken?: (token?: string) => Promise<boolean>
  showConsent?: boolean
  showEmail?: boolean
}

export function StartPageForm({
  allowedEmailDomains,
  onEmailValidationCheck,
  onStart,
  onValidateEmailToken,
  showConsent,
  showEmail
}: Props) {
  const isMobile = useMobile()
  const { colors } = useTheme()
  const translate = useTranslateWithBaseKey("components", "survey", "pages", "start", "StartPageView")

  const { email, setEmail, isValidEmail, isValidDomain, validDomains } = useValidEmail(allowedEmailDomains)
  const [emailError, setEmailError] = useState<string>()
  const [emailValidationError, setEmailValidationError] = useState<string>()
  const [consentGiven, toggleConsentGiven] = useToggle(false)
  const [requiresEmailValidation, setRequiresEmailValidation] = useState(false)
  const [checkedEmail, setCheckedEmail] = useState<string>()

  const invalidConsent = !!showConsent && !consentGiven
  const invalidEmail = !!showEmail && !isValidEmail
  const submitDisabled = invalidEmail || invalidConsent

  const handleValidationCancel = () => setRequiresEmailValidation(false)

  const handleSubmit = async () => {
    setEmailError(undefined)

    if (!showEmail) {
      return onStart?.()
    }

    if (!isValidDomain) {
      return setEmailError(translate("email_domain_error", [validDomains!.join(", ")]))
    }

    if (!!email && checkedEmail !== email) {
      const emailValidationRequired = await onEmailValidationCheck?.(email)
      setRequiresEmailValidation(!!emailValidationRequired)

      if (!emailValidationRequired) setCheckedEmail(email)
    }
  }

  const handleValidateEmailToken = async (token?: string) => {
    setEmailValidationError(undefined)

    const validated = await onValidateEmailToken?.(token)

    if (validated) {
      setCheckedEmail(email)
      setRequiresEmailValidation(false)
      onStart?.()
    } else {
      setEmailValidationError(translate("email_check_token"))
    }
  }

  return (
    <>
      {showEmail && (
        <TextField
          error={!!emailError}
          helperText={emailError}
          label={translate("email")}
          margin
          required
          showErrorOnFocus={false}
          value={email}
          onChange={setEmail}
        />
      )}

      {requiresEmailValidation && (
        <EmailValidationDialog
          error={emailValidationError}
          onCancel={handleValidationCancel}
          onOk={handleValidateEmailToken}
        />
      )}

      {showConsent && (
        <ListItem disableGutters onClick={() => toggleConsentGiven()}>
          <ListItemIcon>
            <CheckBox checked={consentGiven} />
          </ListItemIcon>
          <MarkdownConverter linksInNewWindow linkColor={colors.secondary.toString()}>
            <Markdown content={translate("tos")} />
          </MarkdownConverter>
        </ListItem>
      )}

      <div className={buttonContainerClass}>
        <Button disabled={submitDisabled} fullWidth={isMobile} onClick={handleSubmit}>
          {translate("start")}
        </Button>
      </div>
    </>
  )
}

const buttonContainerClass = style({
  display: "flex",
  marginTop: "2rem",
  ...mobileStyle({ alignItems: "flex-end", flexGrow: 1 })
})
