import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import AssessmentIcon from "@material-ui/icons/Assessment"
import LiveHelpIcon from "@material-ui/icons/LiveHelp"
import TimerIcon from "@material-ui/icons/Timer"
import { style } from "typestyle"

import { useTranslateWithBaseKey } from "core/modules/localization/localizationHooks"
import Markdown from "lib/ui/components/markdown/Markdown"

interface Props {
  estimatedTime?: number
  hasError?: boolean
  hasReport: boolean
  numberOfQuestions?: number
}

export function StartPageInfo({ estimatedTime, hasError, hasReport, numberOfQuestions }: Props) {
  const translate = useTranslateWithBaseKey("components", "survey", "pages", "start", "StartPageView")

  if (hasError) return null

  return (
    <List className={containerClass}>
      {estimatedTime && (
        <ListItem disableGutters>
          <ListItemIcon>
            <TimerIcon />
          </ListItemIcon>
          <Markdown content={translate("estimatedTime", { estimatedTime })} />
        </ListItem>
      )}

      {numberOfQuestions && (
        <ListItem disableGutters>
          <ListItemIcon>
            <LiveHelpIcon />
          </ListItemIcon>
          <Markdown content={translate("numberOfQuestions", { numberOfQuestions })} />
        </ListItem>
      )}

      {hasReport && (
        <ListItem disableGutters>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <Markdown content={translate("reportCustomTextWellbeing")} />
        </ListItem>
      )}
    </List>
  )
}

const containerClass = style({
  fontSize: "1.25rem",
  $nest: {
    p: { margin: 0 }
  }
})
