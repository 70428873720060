import Container from "@material-ui/core/Container"
import { stylesheet } from "typestyle"

import { ReportMyGoals, Survey, SurveyAnswerInstance, SurveyResult, User } from "app/surveys/modules/state/model/Model"
import ReportTopBanner from "app/surveys_app/components/survey/banners/ReportTopBanner"
import { AppConfig } from "app/surveys_app/modules/config/appConfig"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import FadedBlockDiv from "lib/ui/components/layout/FadedBlockDiv"
import TransformElement from "lib/ui/components/layout/TransformElement"

import { ViewComponent } from "../../../base/ViewComponent"
import FeedbackReport from "./FeedbackReport"
import WellbeingReport, { ReportVariant } from "./WellbeingReport"
import ReportSelector from "./report_selector/ReportSelector"

interface Props extends ViewComponentProps {
  isLoading: boolean
  language: string | undefined
  reportVariant: ReportVariant
  selectedReportIndex: number
  showCoachInfo: boolean
  surveyResult: SurveyResult | undefined
  user: User | undefined
  isDownloadingPdf: boolean
  onDownloadPdf: () => void
  onSelectReport: (index: number) => void
  onUpdateMyGoals: (reportMyGoals: ReportMyGoals) => void
}

export default class ReportPageView extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportPageView"]
  }

  constructor(props: Props) {
    super(props)

    setupStyles(this.appConfig, props.reportVariant)

    this.state = {
      showHistoryData: false
    }
  }

  render() {
    const { reportVariant, isDownloadingPdf, onDownloadPdf } = this.props

    return (
      <>
        {reportVariant !== "pdf" && this.renderOverlayItems()}
        {reportVariant !== "pdf" && (
          <ReportTopBanner
            logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_white.svg`}
            reportVariant={reportVariant}
            isDownloadingPdf={isDownloadingPdf}
            onDownloadPdf={onDownloadPdf}
          />
        )}
        {this.renderReportBackground()}
        {this.renderReportArea()}
      </>
    )
  }

  private renderReportArea() {
    const { surveyResult, selectedReportIndex, isLoading, reportVariant, onSelectReport } = this.props

    if (isLoading) return null

    switch (surveyResult?.report_key?.toLowerCase()) {
      case "feedback":
        return (
          <div className={styles.reportRootContainer}>
            <Container maxWidth="lg" className={styles.baseContainer}>
              {this.renderLatestReport()}
            </Container>
          </div>
        )
      case "hintsa":
      default:
        return (
          <div className={styles.reportRootContainer}>
            <Container maxWidth="lg" className={styles.baseContainer}>
              {reportVariant !== "pdf" && (
                <ReportSelector
                  selectedReportIndex={selectedReportIndex}
                  surveyResult={surveyResult}
                  onSelectReport={onSelectReport}
                />
              )}
              <TransformElement selectedTabIndex={selectedReportIndex}>{this.renderAllReports()}</TransformElement>
            </Container>
          </div>
        )
    }
  }

  private renderReport(
    answer: SurveyAnswerInstance,
    isInitialReport: boolean,
    comparisonReport?: SurveyAnswerInstance,
    key?: string | number
  ) {
    const {
      surveyResult,
      reportVariant,
      user,
      language,
      showCoachInfo,
      isDownloadingPdf,
      onDownloadPdf,
      onUpdateMyGoals
    } = this.props

    if (!surveyResult) return <></>

    switch (surveyResult?.report_key?.toLowerCase()) {
      case "feedback":
        return (
          <FeedbackReport
            key={key}
            reportVariant={reportVariant}
            answer={answer}
            survey={surveyResult.survey as Survey}
            user={user}
            language={language!}
            isDownloadingPdf={isDownloadingPdf}
            onDownloadPdf={onDownloadPdf}
          />
        )
      case "hintsa":
      default:
        return (
          <WellbeingReport
            key={key}
            reportVariant={reportVariant}
            answer={answer}
            comparison={comparisonReport}
            isInitialReport={isInitialReport}
            survey={surveyResult.survey as Survey}
            user={user}
            language={language!}
            myGoals={surveyResult?.report_extra?.reportMyGoals}
            showCoachInfo={showCoachInfo}
            isDownloadingPdf={isDownloadingPdf}
            onDownloadPdf={onDownloadPdf}
            onUpdateMyGoals={onUpdateMyGoals}
          />
        )
    }
  }

  private renderAllReports() {
    const { surveyResult } = this.props

    if (!surveyResult) return null

    return surveyResult?.instances.map((answer, i, array) => {
      return this.renderReport(answer, i === array.length - 1, surveyResult?.instances[i - 1], i)
    })
  }

  private renderLatestReport() {
    const { surveyResult } = this.props

    if (!surveyResult?.instances) return <></>
    return this.renderReport(surveyResult?.instances[surveyResult?.instances.length - 1], true)
  }

  private renderReportBackground() {
    return (
      <>
        <div className={styles.reportBackground} />
        <div className={styles.reportBackgroundImage} />
        <div className={styles.reportBackgroundGradient} />
      </>
    )
  }

  private renderOverlayItems() {
    const { isLoading } = this.props

    return <FadedBlockDiv showBlockDiv={isLoading} />
  }
}

let styles

const setupStyles = (appConfig: AppConfig, reportVariant: ReportVariant) => {
  styles = stylesheet({
    reportRootContainer: {
      position: "absolute",
      top: "5rem",
      height: "100%",
      width: "100%",
      color: ReportPageView.theme.colors.fontLightColor.toString()
    },
    reportBackground: {
      position: "fixed",
      width: "100%",
      height: "100%",
      backgroundColor: ReportPageView.theme.colors.hintsa.charcoal.toString(),
      zIndex: -10
    },
    reportBackgroundImage: {
      position: "absolute",
      width: "100%",
      top: "-4.5rem",
      height: reportVariant === "mobile" ? "35rem" : "45rem",
      opacity: 0.5,
      backgroundImage: `url("${appConfig.imagesRoot}/wellbeing_report/report_bg.webp")`,
      backgroundSize: reportVariant === "mobile" ? "180vw " : "cover",
      backgroundRepeat: "no-repeat",
      zIndex: -5,
      backgroundPosition: reportVariant === "mobile" ? "-30vw 8rem" : undefined
    },
    reportBackgroundGradient: {
      position: "absolute",
      width: "100%",
      top: "10rem",
      height: reportVariant === "mobile" ? "21rem" : "30.5rem",
      zIndex: -1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      background: `linear-gradient(to bottom, transparent, ${ReportPageView.theme.colors.hintsa.charcoal.toString()})`
    },
    baseContainer: {
      paddingLeft: reportVariant === "mobile" ? "0rem" : "2rem",
      paddingRight: reportVariant === "mobile" ? "0rem" : "2rem"
    }
  })
}
