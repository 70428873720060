import { useState } from "react"

import { useTranslateWithBaseKey } from "core/modules/localization/localizationHooks"
import { useTheme } from "core/modules/themes/useTheme"
import Dialog from "lib/ui/components/dialogs/Dialog"
import TextField from "lib/ui/components/form/TextField"

interface Props {
  error?: string
  onCancel: () => void
  onOk: (token?: string) => void
}

export function EmailValidationDialog({ error, onCancel, onOk }: Props) {
  const { colors } = useTheme()
  const translate = useTranslateWithBaseKey("components", "survey", "pages", "start", "EmailValidationDialog")

  const [token, setToken] = useState<string>()
  const isTooShort = !!token && token.length < 6

  return (
    <Dialog
      buttons="okCancel"
      okDisabled={!token || isTooShort}
      padding="medium"
      title={translate("title")}
      size="xs"
      onCancel={onCancel}
      onOk={() => onOk(token)}
    >
      <p>{translate("token_sent")}</p>
      <p>{translate("token_please_enter")}</p>

      <TextField
        error={!!error || (!!token && isTooShort)}
        focus
        label={translate("email_token")}
        length={20}
        margin
        required
        value={token?.toUpperCase()}
        onChange={setToken}
      />

      {error && <p style={{ color: colors.errorColor.toString() }}>{error}</p>}

      <p>{translate("email_check_spam")}</p>
    </Dialog>
  )
}
